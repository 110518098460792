import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import HomeHakkımızda from "../components/HomeHakkımızda";
import HomeCalismaAlanlari from "../components/HomeCalismaAlanlari";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Header/>
      <HomeHakkımızda/>
      <HomeCalismaAlanlari/>
      <Footer/>
    </>
  );
};

export default Home;
