import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Hakkımızda = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="bg-slate-200 min-h-[calc(100vh-150px)] " > 

      <div className="container  m-auto px-3 py-12  max-w-[1000px] text-center ">
      <h1 className="text-center text-5xl pb-4 font-semibold underline underline-offset-[10px] text-[#063454] pt-[120px]">
        HAKKIMIZDA
      </h1>
      <br />
      <p className="text-center">
        <span className="font-bold text-[#063454]"> Kaliteli Malzeme</span>
       
        <br />
       Mermer-mezar yapımlarında en kaliteli mermerleri kullanıyoruz
      </p>
      <p className="text-center">
      <span className="font-bold text-[#063454]">Profesyonel Hizmet</span>
        
        <br />
        Alanında en tecrübeli ekip arkadaşlarımız ile çalışıyoruz
      </p>
      <p className="text-center">
      <span className="font-bold text-[#063454]">100% Müşteri Memnuniyeti</span>
        
        <br />
        Çalışmalarımızda önceliğimiz müşterilerimizin memnuniyetidir
      </p>
      <p className="text-center">
      <span className="font-bold text-[#063454]">En Uygun Fiyat</span>
       
        <br />
       Her zaman en uygun fiyat ile müşterilerimize hizmet veriyoruz
      </p>
      
    </div>
    </div>
      <Footer/>
    </>
  );
};

export default Hakkımızda;
