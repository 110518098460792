import React from 'react'
import Slider from "react-slick";
import { headerData } from "../utils/data";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";


const Header = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    arrows: true,
    prevArrow: <LeftArrow/>,
    nextArrow: <RightArrow/>,

  };
  return (
    <div className="mt-[100px] bg-slate-200 header_bg py-5">
       <Slider {...settings} className="m-auto px-2 py-6 w-full max-w-[700px] mx-auto">
                {headerData?.map((item,index) => {
              return (
                <ul className="w-full max-w-[700px]" key={item.id}>
                <li className="list-unstyled mx-1 "><img src={item.img} alt="header"  /></li>
                </ul>
                );
              })}
      </Slider>
    </div>
  )
}

export default Header

