import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { tümHizmetlerimiz } from "../utils/data";

const Navbar = () => {
  const navigate=useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [scrollMenu, setScrollMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const goToTopics=(e)=>{
    const clickedEl = tümHizmetlerimiz.find(
      (item) => item.category == e.target.innerText);
      const encodedTitle = encodeURIComponent(clickedEl.category.replace(/\s/g, "_").toLocaleLowerCase());
      const decodedTitle=decodeURIComponent(encodedTitle)
      navigate(`/hizmetlerimiz/${decodedTitle}`,{state:clickedEl.category});
      
  }

  const openNavbar = () => {
    setOpenMenu(!openMenu);
  };
  const scrollNavbar = () => {
    if (window.scrollY >= 10) {
      setScrollMenu(true);
    } else {
      setScrollMenu(false);
    }
  };
  window.addEventListener("scroll", scrollNavbar);

  return (
    <nav className={`${
      scrollMenu
        ? "navbar_scroll navbar_bg container fixed left-[50%] top-3 translate-x-[-50%] py-3 px-5 z-50 " + (openMenu ? "rounded-tl-[40px] rounded-tr-[40px] " : "rounded-[40px] transition-all duration-500 ease-linear")
        : "navbar_bg flex items-center fixed top-0 left-[50%] translate-x-[-50%] justify-between py-5 px-8 w-full z-50"
    } `}>
      <div className=" flex flex-wrap items-center justify-between mx-auto  w-full">
        <NavLink to="/" className="text-[#e0ae77] logo">
          <img src="/images/logo.png" alt="logo" className="w-full max-w-[100px] rounded-[15px]" />
        </NavLink>
        {!openMenu ? (
          <button
            className=" lg:hidden text-3xl text-[#e0ae77]"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={openNavbar}
          >
            <GiHamburgerMenu />{" "}
          </button>
        ) : (
          <button
            className="  text-3xl text-[#e0ae77] lg:hidden"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={openNavbar}
          >
            <FaTimes />
          </button>
        )}

        <div className="hidden w-full lg:block md:w-auto" id="navbar-default">
          <ul className="font-medium  p-4 md:p-0 mt-4  md:space-x-8 md:mt-0 md:border-0 flex justify-around items-center gap-3">
            <li>
              <NavLink to="/" className="text-[#e0ae77]   font-bold navbar_link relative transition-all duration-300 ease-linear">
                Anasayfa
              </NavLink>
            </li>
            <li >
              <NavLink
                to="/hakkimizda"
                className="text-[#e0ae77]  font-bold navbar_link relative transition-all duration-300 ease-linear"
              >
                Hakkımızda
              </NavLink>
            </li>
            <li className="relative"
             onMouseOver={() => setOpenDropdown(true)}
             onMouseLeave={() => setOpenDropdown(false)}
            >
              <NavLink
                to="/hizmetlerimiz"
                className="text-[#e0ae77] font-bold navbar_link relative transition-all duration-300 ease-linear"
              >
                Hizmetlerimiz
              </NavLink>
              {openDropdown && (
             <ul className="bg-white absolute left-[50%] translate-x-[-50%] text-[12px] w-[240px] p-3 rounded-[10px] shadow-2xl z-50">
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">ESKİ BİNA MERMER TADİLATI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">İNŞAAT İŞLERİ</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">DÖŞEME BASAMAK KAPLAMA</li>
              {/* <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">PENCERE ÖNÜ</li> */}
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">ASANSÖR KAPLAMA</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline"> PENCERE ÖNÜ KÜPEŞTE</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">PATLATMA MERMER DEKORASYONU</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">MEZAR İŞLERİ VE BAŞTAŞI YAPIMI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">DOĞAL TAŞ UYGULAMASI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">MERMER VE GRANİT MONTAJ İŞÇİLİĞİ</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">MERMER ZEMİN SİLİMİ VE CİLALANMASI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">ESKİ BİNA MERMER TADİLATI</li>
             </ul>
              )}
            </li>
            <li >
              <NavLink to="/iletisim" className="text-[#e0ae77] font-bold navbar_link relative transition-all duration-300 ease-linear">
                İletişim
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* <div
        className={`mobile-menu flex flex-col justify-between mx-auto w-full  px-8 absolute top-[98%] left-0  z-100 lg:hidden transition-all duration-700 ease-linear 
        ${openMenu ? "max-h-fit pb-4 md:pb-1 transition-all duration-700 ease-linear " : "max-h-0 transition-all duration-700 ease-linear "}`}
      > */}
        {/* <div    className={`mobile-menu flex flex-col justify-between mx-auto w-full  px-8 absolute top-[98%] left-0  z-100 lg:hidden transition-all duration-700 ease-linear 
        ${openMenu ? "max-h-fit pb-4 md:pb-1 transition-all duration-700 ease-linear " : "max-h-0 transition-all duration-700 ease-linear "}`}
        > */}
          <ul className={`mobile-menu flex flex-col justify-between mx-auto w-full  px-8 absolute top-[98%] left-0  z-100 lg:hidden transition-all duration-500 ease-linear text-center
        ${openMenu ? "h-fit transition-all duration-500 ease-linear " : "h-0 transition-all duration-500 ease-linear "}`}
          >
            <li >
              <NavLink
                to="/"
                className="text-[#e0ae77]  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                Anasayfa
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hakkimizda"
                className="text-[#e0ae77]  font-bold navbar_link relative transition-all duration-300 ease-linear "
                onClick={() => setOpenMenu(false)}
              >
                Hakkımızda
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hizmetlerimiz"
                className="text-[#e0ae77]  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                Hizmetlerimiz
              </NavLink>
            </li>
            <ul className="bg-white mx-auto text-[12px] w-[240px] p-3 rounded-[10px] shadow-2xl">
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">ESKİ BİNA MERMER TADİLATI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">İNŞAAT İŞLERİ</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">DÖŞEME BASAMAK KAPLAMA</li>
              {/* <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">PENCERE ÖNÜ</li> */}
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">ASANSÖR KAPLAMA</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">PENCERE ÖNÜ KÜPEŞTE</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">PATLATMA MERMER DEKORASYONU</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">MEZAR İŞLERİ VE BAŞTAŞI YAPIMI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">DOĞAL TAŞ UYGULAMASI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">MERMER VE GRANİT MONTAJ İŞÇİLİĞİ</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">MERMER ZEMİN SİLİMİ VE CİLALANMASI</li>
              <li onClick={(e)=>goToTopics(e)} className="py-[2px] cursor-pointer text-[#063454] font-semibold hover:underline">ESKİ BİNA MERMER TADİLATI</li>
             </ul>
           
            <li className="pb-3">
              <NavLink
                to="/iletisim"
                className="text-[#e0ae77]  font-bold navbar_link relative transition-all duration-300 ease-linear"
                onClick={() => setOpenMenu(false)}
              >
                İletişim
              </NavLink>
            </li>
          </ul>
        {/* </div> */}
      {/* </div> */}
    </nav>
  );
};

export default Navbar;
