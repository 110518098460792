import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";



const Footer = () => {
  return (
    <footer className=" py-6 px-12  bg-slate-600">
    <div className="flex flex-col-reverse sm:flex-row justify-between items-center">
      
   
    <div>
      <div className="flex justify-start items-center gap-2 ">
        <FaLocationDot className="text-lg text-yellow-600 " />
        <p className="text-sm text-white">Karşıyaka Mah. 929. sk. No:21/A<br/>MERKEZ/ORDU</p>
      </div>
      <div className="flex justify-start items-center gap-2 py-2">
        <FaPhone className="text-lg text-yellow-600" />
        <div className="text-sm text-white">
          <p>0544 422 43 26</p>
        </div>
      </div>
      <div className="flex justify-start items-center gap-2">
        <HiOutlineMail className="text-xl text-yellow-600" />
        <div className="text-sm">
          <a
            href="mailto:ordugarantimermer@outlook.com"
            className="block text-white"
          >
                ordugarantimermer@outlook.com
          </a>
        </div>
      </div>
      <div className="flex justify-start items-center">
      <IoShareSocialSharp className="text-xl text-yellow-600" />
        <div className="text-sm py-2">
        <a href="https://www.instagram.com/ordugarantimermer/" target="_blank" className="rounded-full instagram flex justify-center items-center w-full max-w-[32px] p-2 mx-2 hover:scale-125 transition-transform duration-400 ease-linear"><FaInstagram className="text-white "/></a>

        </div>
      </div>
    </div>

    <NavLink to="/" className="text-yellow-600 logo text-xl pb-3 sm:pb-0 text-center font-bold">
    <img src="/images/logo.png" className="w-full max-w-[120px] rounded-[15px]"/>
    </NavLink>
    </div>

  </footer>
  );
};

export default Footer;
