import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Hakkımızda from "../pages/Hakkımızda";
import CalismaAlanlar from "../pages/CalismaAlanlar";
import Iletisim from "../pages/Iletisim";
import Category from '../pages/Category';


const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/hakkimizda" element={<Hakkımızda/>} />
        <Route path="/hizmetlerimiz" element={<CalismaAlanlar/>} />
        {/* <Route path="/yayinlar" element={<Yayinlar/>} /> */}
        {/* <Route path="/ekibimiz" element={<Ekibimiz/>} /> */}
        <Route path="/iletisim" element={<Iletisim/>} />
        {/* <Route path="/biography/:id/:name" element={<Attorney_Bio/>} /> */}
        <Route path="/hizmetlerimiz/:title" element={<Category/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
