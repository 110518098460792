import React from "react";

const HomeHakkımızda = () => {
  return (
    <div className="m-auto px-6 md:px-12 lg:px-[110px] text-justify py-12  bg-slate-200 nocopy_paste">
      <div className="container m-auto text-center">
     
      <h1 className="text-center text-5xl pb-4 font-semibold underline underline-offset-[10px] text-[#063454]">
        HAKKIMIZDA
      </h1>
      <br />
      <p>
        <span className="font-bold text-[#063454]"> Kaliteli Malzeme</span>
       
        <br />
       Mermer-mezar yapımlarında en kaliteli mermerleri kullanıyoruz
      </p>
      <p>
      <span className="font-bold text-[#063454]">Profesyonel Hizmet</span>
        
        <br />
        Alanında en tecrübeli ekip arkadaşlarımız ile çalışıyoruz
      </p>
      <p>
      <span className="font-bold text-[#063454]">100% Müşteri Memnuniyeti</span>
        
        <br />
        Çalışmalarımızda önceliğimiz müşterilerimizin memnuniyetidir
      </p>
      <p>
      <span className="font-bold text-[#063454]">En Uygun Fiyat</span>
       
        <br />
       Her zaman en uygun fiyat ile müşterilerimize hizmet veriyoruz
      </p>
      
         
      </div>
    </div>
  );
};

export default HomeHakkımızda;
