import { createContext } from 'react'

export const AppContext=createContext()

const AppContextProvider = ({children}) => {

    const goToArticleFromImg=(e)=>{
        // let num = e.target.src.indexOf("/images");
        // const item = articles.find(
        //   (item) => item.img == e.target.src.slice(num)
        // );
       
        // navigate(`/article/${item.id}/${item.template}`);
      
      }

  return (
    <AppContext.Provider value={{
       goToArticleFromImg
        }}>
{children}
    </AppContext.Provider>
  )
}

export default AppContextProvider