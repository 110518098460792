import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Iletisim = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="bg-slate-200 min-h-[calc(100vh - 500px)] pt-[100px]">
        <div className=" px-3 pt-10 lg:pb-10  flex flex-col lg:flex-row items-center justify-center max-w-[1200px] mx-auto">
          <div className="lg:w-4/12 text-[#063454] font-semibold pb-5 lg:pb-0 ">
            <h3 className="text-[#063454] font-bold  text-xl py-3 sm:w-[300px] m-auto">
              İLETİŞİM BİLGİLERİ
            </h3>
            <div className="flex justify-start items-center gap-3 sm:w-[300px] m-auto">
              <FaLocationDot className="text-2xl text-[#e0ae77] " />
              <p className="text-md ">
              Karşıyaka Mah. 929. sk. No:21/A<br/>MERKEZ/ORDU
              </p>
            </div>
            <div className="flex justify-start items-center gap-3 py-2 sm:w-[300px] m-auto">
              <FaPhone className="text-2xl text-[#e0ae77]" />
              <div className="text-md ">
              <p>0544 422 43 26</p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-3 sm:w-[300px] m-auto">
              <HiOutlineMail className="text-2xl text-[#e0ae77]" />
              <div className="text-md">
                <a href="mailto:ordugarantimermer@outlook.com" className="block">
                ordugarantimermer@outlook.com
                </a>
                
              </div>
            </div>
          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4259.995889170576!2d37.905042848796086!3d40.97552486173506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406321c122a6fa05%3A0x8fc74bc23993aa88!2sKar%C5%9F%C4%B1yaka%2C%20929.%20Sk.%20No%3A21%2C%2052200%20Ordu%20Merkez%2FOrdu!5e0!3m2!1sen!2str!4v1704638555887!5m2!1sen!2str" 
           width="600"
           height="450"
           allowfullscreen=""
           loading="lazy"
           referrerpolicy="no-referrer-when-downgrade"
           className="border-0 m-auto w-full lg:max-w-[700px] "
>
          </iframe>
       
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Iletisim;
