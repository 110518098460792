import React, { useEffect } from 'react'
import { useLocation} from 'react-router-dom';
import { tümHizmetlerimiz } from '../utils/data';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';


const Category = () => {
  const {state}=useLocation()
  const currentHizmet= tümHizmetlerimiz.filter((item) => item.category == state);


  useEffect(() => {
    window.scrollTo(0, 500);
  }, []);
  
  return (
    <>
    <Navbar/>
    <h1 className='pt-[150px] bg-slate-200 text-[#063454] font-bold text-2xl sm:text-3xl text-center'>{state}</h1>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  min-h-[calc(100vh - 150px)] gap-5 containermx-auto px-12 py-16 bg-slate-200'>
      {
  currentHizmet && currentHizmet.length > 0 ? (
    currentHizmet.map((item) => (
      <img src={item.img} key={item.id} alt={`Image ${item.id}`} />
    ))
  ) : (
    <p>no data</p>
  )
}

      </div>
 
    <Footer/>
    
  </>
  )
}

export default Category