import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import CalismaAlani from "../components/CalismaAlani";
import Footer from "../components/Footer";
import { hizmetlerimiz, tümHizmetlerimiz } from "../utils/data";
import { useNavigate } from "react-router-dom";

const CalismaAlanlar = () => {
  const navigate=useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const goToTopics=(e)=>{
    const clickedEl = tümHizmetlerimiz.find(
      (item) => item.category == e.target.innerText);
      const encodedTitle = encodeURIComponent(clickedEl.category.replace(/\s/g, "_").toLocaleLowerCase());
      const decodedTitle=decodeURIComponent(encodedTitle)
      navigate(`/hizmetlerimiz/${decodedTitle}`,{state:clickedEl.category});
      
  }


  const goToArticleFromImg=(e)=>{
    let num = e.target.src.indexOf("/images");
    const clickedEl = hizmetlerimiz.find(
      (item) => item.img == e.target.src.slice(num)
    );
    // console.log(clickedEl)
    const encodedTitle = encodeURIComponent(clickedEl.title.replace(/\s/g, "_").toLocaleLowerCase());
    const decodedTitle=decodeURIComponent(encodedTitle)
    navigate(`/hizmetlerimiz/${decodedTitle}`,{state:clickedEl.title});
    // console.log(e.target.src)
  }
  return (
    <>
      <Navbar/>
      <div className='m-auto px-12 md:px-[110px] text-justify pt-12 pb-6 bg-slate-300'>
    <h1 className='text-center text-2xl sm:text-4xl md:text-5xl pb-4 font-semibold underline underline-offset-[15px] text-[#063454] pt-[120px]'>HİZMETLERİMİZ</h1>
   <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 pt-8'>
   {tümHizmetlerimiz?.map((item,index) => {
              return (
                <ul className="w-full max-w-[700px]" key={item.id}>
                <li className="list-unstyled mx-1 relative cursor-pointer z-10" >
                  <img src={item.img} alt="hizmet" onClick={(e)=>goToArticleFromImg(e)} />
                </li>
                </ul>
                );
              })}

   </div>
</div>
      <Footer/>
    </>
  );
};

export default CalismaAlanlar;
