import React from 'react'
import {IoChevronBackOutline} from 'react-icons/io5';


const LeftArrow = ({onClick}) => {
  return (
    <div className='flex absolute left-0 top-[50%] translate-y-[-50%] w-fit z-30'>
      <button className='text-[40px] font-bold' onClick={onClick}>
        <IoChevronBackOutline className='text-[#063454]'/>

      </button>
    </div>
  )
}

export default LeftArrow