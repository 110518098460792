
export const headerData = [
  {
    id:1,
    img:"/images/header_1.png",
  },
  {
    id:2,
    img:"/images/header_2.png",
  },
  {
    id:3,
    img:"/images/header_3.png",
  },
  {
    id:4,
    img:"/images/header_4.png",
  },
  {
    id:5,
    img:"/images/header_5.png",
  },
 

]
export const hizmetlerimiz = [
  {
    id:1,
    img:"/images/mutfak_4.png",
    title:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:2,
    img:"/images/insaat_5.png",
    title:"İNŞAAT İŞLERİ",
  },
  {
    id:3,
    img:"/images/header_3.png",
    title:"DÖŞEME BASAMAK KAPLAMA",
  },

  {
    id:5,
    img:"/images/asansor_1.png",
    title:"ASANSÖR KAPLAMA",
  },
  {
    id:6,
    img:"/images/kupeste_4.png",
    title:"PENCERE ÖNÜ KÜPEŞTE",
  },
  {
    id:7,
    img:"/images/patlatma_1.png",
    title:"PATLATMA MERMER DEKORASYONU",
  },
  {
    id:8,
    img:"/images/mezar_14.png",
    title:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:9,
    img:"/images/dogal_tas_5.png",
    title:"DOĞAL TAŞ UYGULAMASI",
  },
  {
    id:10,
    img:"/images/montaj_1.png",
    title:"MERMER VE GRANİT MONTAJ İŞÇİLİĞİ",
  },
  {
    id:11,
    img:"/images/zemin_9.png",
    title:"MERMER ZEMİN SİLİMİ VE CİLALANMASI",
  },
  {
    id:12,
    img:"/images/mutfak_11.png",
    title:"ESKİ BİNA MERMER TADİLATI",
  },
 

]
export const tümHizmetlerimiz = [
  {
    id:1,
    img:"/images/mezar_1.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:2,
    img:"/images/mezar_2.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:3,
    img:"/images/mezar_3.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:4,
    img:"/images/mezar_4.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:5,
    img:"/images/mezar_5.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:6,
    img:"/images/mezar_6.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:7,
    img:"/images/mezar_7.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:8,
    img:"/images/mezar_8.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:9,
    img:"/images/mezar_9.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:10,
    img:"/images/mezar_10.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:11,
    img:"/images/mezar_11.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:12,
    img:"/images/mezar_12.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:13,
    img:"/images/mezar_13.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:14,
    img:"/images/mezar_14.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:15,
    img:"/images/mezar_15.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:16,
    img:"/images/mezar_16.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:17,
    img:"/images/mezar_17.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:18,
    img:"/images/mezar_18.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:19,
    img:"/images/mezar_19.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:20,
    img:"/images/mezar_20.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:21,
    img:"/images/mezar_21.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:22,
    img:"/images/mezar_22.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:23,
    img:"/images/mezar_23.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:24,
    img:"/images/mezar_24.png",
    category:"MEZAR İŞLERİ VE BAŞTAŞI YAPIMI",
  },
  {
    id:25,
    img:"/images/merdiven_1.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:26,
    img:"/images/merdiven_2.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:27,
    img:"/images/merdiven_3.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:28,
    img:"/images/merdiven_4.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:29,
    img:"/images/merdiven_5.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:30,
    img:"/images/merdiven_6.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:31,
    img:"/images/merdiven_7.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:32,
    img:"/images/merdiven_8.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:33,
    img:"/images/merdiven_9.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:34,
    img:"/images/mutfak_1.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:35,
    img:"/images/mutfak_2.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:36,
    img:"/images/mutfak_3.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:37,
    img:"/images/mutfak_4.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:38,
    img:"/images/mutfak_5.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:39,
    img:"/images/mutfak_6.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:40,
    img:"/images/mutfak_7.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:41,
    img:"/images/mutfak_8.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:42,
    img:"/images/mutfak_9.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:43,
    img:"/images/mutfak_10.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:44,
    img:"/images/mutfak_11.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:45,
    img:"/images/asansor_1.png",
    category:"ASANSÖR KAPLAMA",
  },
  {
    id:46,
    img:"/images/zemin_6.png",
    category:"MERMER ZEMİN SİLİMİ VE CİLALANMASI",
  },
  {
    id:47,
    img:"/images/zemin_7.png",
    category:"MERMER ZEMİN SİLİMİ VE CİLALANMASI",
  },
  {
    id:48,
    img:"/images/zemin_8.png",
    category:"MERMER ZEMİN SİLİMİ VE CİLALANMASI",
  },
  {
    id:49,
    img:"/images/insaat_1.png",
    category:"İNŞAAT İŞLERİ",
  },
  {
    id:50,
    img:"/images/kaplama_1.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:51,
    img:"/images/patlatma_1.png",
    category:"PATLATMA MERMER DEKORASYONU",
  },
  {
    id:52,
    img:"/images/patlatma_2.png",
    category:"PATLATMA MERMER DEKORASYONU",
  },
  {
    id:53,
    img:"/images/patlatma_3.png",
    category:"PATLATMA MERMER DEKORASYONU",
  },
  {
    id:54,
    img:"/images/kaplama_2.png",
    category:"DÖŞEME BASAMAK KAPLAMA",
  },
  {
    id:55,
    img:"/images/mutfak_12.png",
    category:"MUTFAK VE BANYO TEZGAHI",
  },
  {
    id:56,
    img:"/images/zemin_3.png",
    category:"MERMER ZEMİN SİLİMİ VE CİLALANMASI",
  },
  {
    id:57,
    img:"/images/insaat_2.png",
    category:"İNŞAAT İŞLERİ",
  },
  {
    id:58,
    img:"/images/insaat_3.png",
    category:"İNŞAAT İŞLERİ",
  },
  {
    id:59,
    img:"/images/insaat_4.png",
    category:"İNŞAAT İŞLERİ",
  },
  {
    id:60,
    img:"/images/insaat_5.png",
    category:"İNŞAAT İŞLERİ",
  },
  {
    id:61,
    img:"/images/dogal_tas_1.png",
    category:"DOĞAL TAŞ UYGULAMASI",
  },
  {
    id:62,
    img:"/images/dogal_tas_2.png",
    category:"DOĞAL TAŞ UYGULAMASI",
  },
  {
    id:63,
    img:"/images/dogal_tas_3.png",
    category:"DOĞAL TAŞ UYGULAMASI",
  },
  {
    id:64,
    img:"/images/dogal_tas_4.png",
    category:"DOĞAL TAŞ UYGULAMASI",
  },
  {
    id:65,
    img:"/images/dogal_tas_5.png",
    category:"DOĞAL TAŞ UYGULAMASI",
  },
  {
    id:66,
    img:"/images/kupeste_1.png",
    category:"PENCERE ÖNÜ KÜPEŞTE",
  },
  {
    id:67,
    img:"/images/kupeste_2.png",
    category:"PENCERE ÖNÜ KÜPEŞTE",
  },
  {
    id:68,
    img:"/images/kupeste_3.png",
    category:"PENCERE ÖNÜ KÜPEŞTE",
  },
  {
    id:69,
    img:"/images/kupeste_4.png",
    category:"PENCERE ÖNÜ KÜPEŞTE",
  },


  /*********************************************** */
 





  {
    id:499,
    img:"/images/mutfak_12.png",
    category:"MERMER VE GRANİT MONTAJ İŞÇİLİĞİ",
  },
  {
    id:498,
    img:"/images/montaj_1.png",
    category:"MERMER VE GRANİT MONTAJ İŞÇİLİĞİ",
  },
  {
    id:497,
    img:"/images/montaj_2.png",
    category:"MERMER VE GRANİT MONTAJ İŞÇİLİĞİ",
  },

  {
    id:701,
    img:"/images/mutfak_10.png",
    category:"ESKİ BİNA MERMER TADİLATI",
  },
  {
    id:711,
    img:"/images/mutfak_11.png",
    category:"ESKİ BİNA MERMER TADİLATI",
  },


]




